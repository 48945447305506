<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center"
          ><span style="color: #fff">
            {{ msg }}
          </span></v-flex
        >
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm8>
        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Modules</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm2 text-right>
        <v-dialog
          :retain-focus="false"
          v-model="dialogExist"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="green" v-bind="attrs" v-on="on">
              SELECT MODULES
            </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Select Modules</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-layout wrap justify-center v-if="selectModules.length > 0">
                    <v-flex xs12 v-for="(mod, m) in selectModules" :key="m">
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsmedium; font-size: 20px"
                            >{{ mod.courseName }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-center py-2>
                        <v-flex xs10 v-for="(mods, s) in mod.modules" :key="s">
                          <v-layout wrap>
                            <v-flex xs1 v-if="mods">
                              <v-checkbox
                                @click="removeItem(mods)"
                                v-model="mods.isSelected"
                                color="green"
                                hide-details
                              ></v-checkbox>
                            </v-flex>
                            <v-flex xs11 v-if="mods">
                              <span
                                style="
                                  font-family: poppinsregular;
                                  font-size: 16px;
                                "
                                >{{ mods.moduleName }}</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-center py-2 v-if="mods">
                            <v-flex xs10 v-if="mods.isSelected == true">
                              <v-layout wrap>
                                <v-flex xs10 sm10>
                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    v-model="mods.moduleNumber"
                                    label="Module Number"
                                    required
                                    :hide-details="true"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex
                                  xs2
                                  text-right
                                  v-if="!ids.includes(mods._id)"
                                >
                                  <v-btn
                                    dark
                                    color="green"
                                    @click="addModuleItems(mods)"
                                    >Add</v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center v-else>
                    <v-flex xs12 py-16 text-center>
                      <span style="font-family: poppinsbold; font-size: 25px"
                        >No modules available</span
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogExist = false">
                  Close
                </v-btn>
                <v-btn
                  v-if="selectModules.length > 0"
                  color="blue darken-1"
                  text
                  @click="addSelectedModules()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs12 sm2 text-right>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="600px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="green" v-bind="attrs" v-on="on"> ADD </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Add Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        v-model="moduleNo"
                        label="Module Number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="moduleName"
                        label="Module Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Add Module Details</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="moduleDetails"></vue-editor>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="add()"> Save </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-5 v-if="moduleList.length > 0">
      <v-flex xs12 sm6 md4 pa-2 v-for="(item, i) in moduleList" :key="i">
        <v-card height="200px">
          <v-layout wrap py-5 px-4>
            <v-flex xs12>
              <span style="font-family: poppinssemibold; font-size: 20px"
                >Module : {{ item.moduleNo }}</span
              >
            </v-flex>
            <v-flex xs12 pt-2>
              <span style="font-family: poppinsmedium; font-size: 17px">{{
                item.moduleName
              }}</span>
            </v-flex>
            <v-layout wrap>
              <v-flex xs6 sm4 pa-2 pt-10>
                <v-btn
                  small
                  block
                  class="mr-2"
                  outlined
                  color="blue"
                  :to="'/moduleView?id=' + item._id"
                >
                  View
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 pa-2 pt-10>
                <v-btn
                  small
                  block
                  class="mr-2"
                  outlined
                  color="green"
                  @click="editSlider(item)"
                >
                  Edit
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 pa-2 pt-10>
                <v-dialog v-model="item.delete" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                    >
                      Delete
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete this
                      Module?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="item.delete = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(item)"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-else>
      <v-flex xs12 py-16 text-center>
        <span style="font-family: poppinsbold; font-size: 25px"
          >No modules available</span
        >
      </v-flex>
    </v-layout>
    <v-dialog v-model="editdialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Module</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                outlined
                dense
                type="number"
                v-model="editingitem.moduleNo"
                label="Module Number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                outlined
                dense
                v-model="editingitem.moduleName"
                label="Module Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>Edit Module Details</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="editingitem.moduleDetails"></vue-editor>
              </div>
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    // ImageComp,
    VueEditor,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      dialogExist: false,
      editdialog: false,
      editingitem: {},

      appLoading: false,
      dialogDelete: false,
      attachments: null,
      attachmentsformData: new FormData(),
      cId: "",
      moduleList: {},
      moduleNo: "",
      moduleName: "",
      moduleDetails: "",
      courseImage: "",
      selectModules: [],
      moduleNumber: "",
      selectArray: [],
      ids: [],
    };
  },

  mounted() {
    this.getData();
    this.getModules();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/module/list",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          courseId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.moduleList = response.data.modules;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getModules() {
      this.appLoading = true;
      axios({
        url: "/module/couses/list",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          courseId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.selectModules = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["moduleNo"] = this.moduleNo;
      user["moduleName"] = this.moduleName;
      user["moduleDetails"] = this.moduleDetails;
      user["courseId"] = this.$route.query.id;

      axios({
        url: "/module/add",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.dialog = false;
            (this.moduleNo = null),
              (this.moduleName = ""),
              (this.moduleDetails = "");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addModuleItems(data) {
      var obj = {};
      obj.moduleNumber = data.moduleNumber;
      obj.moduleId = data._id;
      this.selectArray.push(obj);
      this.ids.push(data._id);
    },
    removeItem(i) {
      if (i.isSelected == false) {
        this.selectArray.splice(i, 1);
      }
    },
    addSelectedModules() {
      this.appLoading = true;
      axios({
        url: "/module/add/multicourse",
        method: "POST",
        data: {
          courseId: this.$route.query.id,
          details: this.selectArray,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialogExist = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.dialog = false;
            (this.moduleNo = null),
              (this.moduleName = ""),
              (this.moduleDetails = "");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.category = this.editingitem.category._id;
      this.subCategory = this.editingitem.subCategory._id;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["moduleNo"] = this.editingitem.moduleNo;
      user["moduleName"] = this.editingitem.moduleName;
      user["moduleDetails"] = this.editingitem.moduleDetails;
      user["id"] = this.editingitem._id;
      axios({
        url: "/module/edit",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            // if (this.courseImage) {
            //     this.uploadImage(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
            (this.editingitem.moduleNo = null),
              (this.editingitem.moduleName = ""),
              (this.editingitem.moduleDetails = "");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/module/delete",
        method: "GET",
        params: {
          id: r._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>