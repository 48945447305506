<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title
            class="elevation-1"
            style="font-family: poppinsextrabold"
          >
            Course Details
            <v-spacer></v-spacer>
          </v-card-title>
          <!-- <div class="pt-4">
            <v-row>
              <v-col cols="12" md="12"
                ><v-img :src="mediaURL + course.image"
                  ><template v-slot:placeholder>
                    <ImageLoader /> </template></v-img
              ></v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" v-if="course.courseName">
                    <span class="item_title">{{ course.courseName }}</span>
                    <hr />
                    <br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="course.category">
                    <span style="font-family: poppinsmedium"
                      >Category :
                      <v-chip
                        class="item_value ma-2"
                        style="font-family: poppinsmedium"
                        >{{ course.category.name }}</v-chip
                      ></span
                    >
                  </v-col>
                  <v-col>
                    <span
                      v-if="course.subCategory"
                      style="font-family: poppinsmedium"
                      >Sub Category :
                      <v-chip
                        class="item_value ma-2"
                        style="font-family: poppinsmedium"
                        >{{ course.subCategory.name }}</v-chip
                      ></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="course.courseAmount">
                    <span style="font-family: poppinsmedium"
                      >Course Amount : US ${{ course.courseAmount }}</span
                    >
                  </v-col>
                  <v-col v-if="course.offerPrice">
                    <span style="font-family: poppinsmedium"
                      >Offer Price :  US ${{ course.offerPrice }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12"
                    ><span style="font-family: poppinssemibold; font-size: 18px"
                      >Course Overview:</span
                    ></v-col
                  >
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value">
                      {{ course.overView.modeOfEducation }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value">
                      {{ course.overView.security }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value">
                      {{ course.overView.language }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value">
                      {{ course.overView.certification }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value"> {{ course.overView.mode }}</span>
                  </v-col>
                  <v-col cols="12" md="12" v-if="course.overView">
                    <span class="item_value">
                      {{ course.overView.cpdPoints }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-tabs
                  v-model="tab"
                  background-color="#DFFFDBCC"
                  color="#3CB043"
                  dark
                  grow
                >
                  <v-tab v-for="(item, i) in items" :key="i">
                    <span
                      style="font-family: poppinsmedium"
                      :style="tab == i ? 'color: black' : 'color: #635E5E'"
                    >
                      {{ item }}</span
                    >
                  </v-tab>
                  <v-tab-item>
                    <v-layout wrap justify-start pb-10 pt-5>
                      <v-flex xs12 text-left py-5>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 20px;
                          "
                          >Course Modules</span
                        >
                      </v-flex>
                      <v-flex
                        xs12
                        v-for="(list, l) in moduleList"
                        :key="l"
                        text-left
                      >
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 17px;
                          "
                          >Module {{ list.moduleNo }} :
                          {{ list.moduleName }}</span
                        >
                        <v-flex xs12 py-5 px-8>
                          <span
                            style="
                              color: #000000;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            v-html="list.moduleDetails"
                          ></span>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item>
                    <v-col cols="12" md="12">
                      <v-row>
                        <v-col cols="12" md="12">
                          <span
                            class="item_value"
                            v-html="course.learningOutComes"
                          ></span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>
                  <v-tab-item>
                    <v-col cols="12" md="12">
                      <v-row>
                        <v-col cols="12" md="12">
                          <span
                            class="item_value"
                            v-html="course.whoShouldAttend"
                          ></span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-row>
                        <v-col cols="12" md="12">
                          <span
                            class="item_value"
                            v-html="course.assesmentAndEvaluation"
                          ></span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </div> -->
          <v-layout wrap justify-center>
            <v-flex xs11 sm12>
              <v-layout wrap v-if="courseView">
                <v-flex xs12 sm12 md8 pt-8>
                  <v-img :src="mediaURL + courseView.image"> </v-img>
                  <v-layout wrap>
                    <v-flex xs12 text-left py-8>
                      <span
                        style="
                          font-family: poppinsbold;
                          color: #000000;
                          font-size: 24px;
                        "
                        >{{ courseView.courseName }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pr-5>
                      <v-tabs
                        v-model="tab"
                        background-color="#DFFFDBCC"
                        color="#3CB043"
                        dark
                        grow
                      >
                        <v-tab v-for="(item, i) in items" :key="i">
                          <span
                            style="font-family: poppinsmedium"
                            :style="
                              tab == i ? 'color: black' : 'color: #635E5E'
                            "
                          >
                            {{ item }}</span
                          >
                        </v-tab>
                        <v-tab-item>
                          <v-layout wrap justify-start pb-10 pt-5>
                            <v-flex xs12 text-left py-5>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsmedium;
                                  font-size: 20px;
                                "
                                >Course Modules</span
                              >
                            </v-flex>
                            <v-flex
                              xs12
                              v-for="(list, l) in moduleList"
                              :key="l"
                              text-left
                            >
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsmedium;
                                  font-size: 17px;
                                "
                                >Module {{ list.moduleNo }} :
                                {{ list.moduleName }}</span
                              >
                              <v-flex xs12 py-5 px-8>
                                <span
                                  style="
                                    color: #000000;
                                    font-family: poppinsregular;
                                    font-size: 14px;
                                  "
                                  v-html="list.moduleDetails"
                                ></span>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                        </v-tab-item>
                        <v-tab-item>
                          <v-layout wrap pb-16>
                            <v-flex xs12 text-left py-10>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsmedium;
                                  font-size: 20px;
                                "
                                >Learning Outcomes:</span
                              >
                            </v-flex>
                            <v-flex xs12 px-8 text-left>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsregular;
                                  font-size: 14px;
                                "
                                v-html="courseView.learningOutComes"
                              ></span>
                            </v-flex>
                          </v-layout>
                        </v-tab-item>
                        <v-tab-item>
                          <v-layout wrap pb-10 pt-5>
                            <v-flex xs12 text-left py-5>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsmedium;
                                  font-size: 20px;
                                "
                                >Who Should Attend :</span
                              >
                            </v-flex>
                            <v-flex xs12 py-2 px-8 text-left>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsregular;
                                  font-size: 14px;
                                "
                                v-html="courseView.whoShouldAttend"
                              ></span>
                            </v-flex>
                            <v-flex xs12 text-left py-5>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsmedium;
                                  font-size: 20px;
                                "
                                >Assessment & Certification</span
                              >
                            </v-flex>
                            <v-flex xs12 py-2 px-8 text-left>
                              <span
                                style="
                                  color: #000000;
                                  font-family: poppinsregular;
                                  font-size: 14px;
                                "
                                v-html="courseView.assesmentAndEvaluation"
                              ></span>
                            </v-flex>
                          </v-layout>
                        </v-tab-item>
                      </v-tabs>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm12 md4 pt-8>
                  <v-layout wrap justify-start>
                    <v-flex xs1 class="hidden-sm-and-down"> </v-flex>
                    <v-flex xs11>
                      <v-layout
                        wrap
                        justify-center
                        py-5
                        style="background-color: #f9f9f9"
                      >
                        <v-flex xs12 py-5 text-left pl-5>
                          <v-layout wrap>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Clock.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.modeOfEducation }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pl-5 pb-5>
                          <v-layout wrap justify-start>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Lock.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 text-left px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.security }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-5 text-left pl-5>
                          <v-layout wrap>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Microphone.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.language }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-5 text-left pl-5>
                          <v-layout wrap>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Certificate.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.certification }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-5 text-left pl-5>
                          <v-layout wrap>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Globe.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.mode }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-5 text-left pl-5>
                          <v-layout wrap>
                            <v-flex xs2 sm1 md1 pa-1>
                              <v-img
                                src="../../../assets/images/Medal.png"
                              ></v-img>
                            </v-flex>
                            <v-flex xs10 sm11 md11 px-5>
                              <span
                                style="
                                  font-family: poppinsmedium;
                                  color: #000000;
                                  font-size: 14px;
                                "
                                >{{ courseView.overView.cpdPoints }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs10 sm10 text-center py-5>
                          <v-btn
                            color="rgba(60, 176, 67, 0.06)"
                            depressed
                            block
                          >
                            <span
                              style="
                                color: #3cb043;
                                font-family: poppinsbold;
                                font-size: 18px;
                              "
                            >
                              US ${{ courseView.offerPrice }}</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <Modules />
        <Exam
          :examDetails="examDetails"
          :isExam="isExam"
          @stepper="winStepper"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Modules from "./modules";
import Exam from "./exam";
import axios from "axios";
export default {
  components: {
    Modules,
    Exam,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      courseView: "",
      examDetails: [],
      data: [],
      isExam: "",
      moduleList: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      tab: null,
      items: ["Curriculum", "Outcomes", "Audience & Certification"],
    };
  },
  mounted() {
    this.getData();
    this.getModules();
  },
  methods: {
    winStepper(item) {
      if (item.getData) {
        this.getData();
      }
      if (item.response) {
        this.msg = item.response.msg;
        this.showSnackBar = true;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.courseView = response.data.data;
          this.examDetails = response.data.examSettings;
          this.isExam = response.data;
          // this.name = response.data.data.name;
          // this.categoryArray = response.data.data.category;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getModules() {
      this.appLoading = true;
      axios({
        url: "/module/list",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          courseId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.moduleList = response.data.modules;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>
  